import React from 'react';
import Image from '@/components/elements/Image';
import SectionRichText from '../SectionRichText';
import type { TimelineTypes } from './Timeline.types';
import * as styles from './Timeline.module.scss';

export default function Timeline({ body, steps, eyebrow }: TimelineTypes) {
  const stepsStyles = {
    headingH2: styles.stepHeading,
    headingH3: styles.stepHeading,
    paragraph: styles.stepParagraph,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={`col-md-8 ${styles.textContent}`}>
          <p className={styles.eyebrow}>{eyebrow}</p>
          {body && <SectionRichText body={body} className={styles} />}
        </div>
        {steps && (
          <ul className={`col-md-4 ${styles.stepsList}`}>
            {steps.map(({ _uid, body: stepBody, icon }) => (
              <li key={_uid} className={styles.step}>
                {icon && (
                  <div className={styles.iconWrapper}>
                    <Image data={icon} fixed options={{ width: 25 }} />
                  </div>
                )}
                {stepBody && <SectionRichText body={stepBody} className={stepsStyles} />}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
